import Controller from '../../javascript/controllers/outside_click_handler_controller';

export default class extends Controller {
  static targets = ['inputContainer', 'list', 'search', 'error', 'noResults'];

  connect() {
    super.connect();

    this.#initializeElement();
    this.element.addEventListener('reflex:morph', () => {
      this.#initializeElement();
      if (this.isOpen) this.open();
    });
  }

  filter(event) {
    if (['Shift', 'Tab'].includes(event.key)) return;

    const query = event.target.value;
    const values = this.allItems.filter(li => {
      if (li.dataset.header === 'true') return true;
      return li.innerText.trim().toLowerCase().includes(query.toLowerCase());
    });

    if (values.length === 0) {
      this.listTarget.replaceChildren(this.noResultsTarget.content.cloneNode(true));
    } else {
      this.listTarget.replaceChildren(...values);
    }
  }

  select(ev) {
    const item = ev.target;
    const text = item.querySelector('span[data-selected-value]').innerText;

    this.input.value = item.dataset.value;
    this.searchTarget.value = text.trim();
    this.input.dispatchEvent(new Event('change'));
    this.close();
  }

  open() {
    this.listTarget.classList.remove('hidden');
    this.isOpen = true;
  }

  close() {
    this.listTarget.classList.add('hidden');
    this.isOpen = false;

    if (this.input.value === '') {
      this.searchTarget.value = '';
      this.filter({ target: { value: '' } });
    }
  }

  toggle() {
    if (this.listTarget.classList.contains('hidden')) {
      this.open();
    } else {
      this.close();
    }
  }

  #initializeElement() {
    this.input = this.inputContainerTarget.querySelector('input');
    this.domId = Date.now();
    this.listTarget.setAttribute('id', this.domId);
    this.allItems = Array.from(this.element.querySelectorAll('li'));
    this.searchTarget.setAttribute('id', this.input.name);

    this.element.querySelectorAll('li[data-value]').forEach(li => {
      if (li.dataset.value === this.input.value) {
        const text = li.querySelector('span[data-selected-value]').innerText;
        this.searchTarget.value = text.trim();
      }
    });

    const error = this.element.querySelector('div.hidden > span.error');
    if (error) {
      this.errorTarget.innerHTML = error.innerHTML;
    }
    this.filter({ target: { value: '' } });
    this.element.addEventListener('keydown', event => {
      if (event.key === 'Tab') {
        this.close();
        this.input.dispatchEvent(new Event('change'));
      }
    });
  }
}
